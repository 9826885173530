<template>
  <div class="overviewTop">
    <div class="tipBox">
      <p>账户余额</p>
      <div class="ye" style="color:#E3405B">{{ detail.balance_account ? detail.balance_account : 0 | unitPrice("￥") }}
      </div>
      <el-button @click="recharge" type="primary">充值</el-button>
    </div>
    <el-dialog-x-dialog :title="loadingtitle" :visible.sync="editVisible">
      <el-form class="rechargeFrom" :model="goodsAuditForm" ref="goodsAuditForm" :rules="txRules" label-width="135px">
        <el-form-item label="站点名称" prop="shop_name">
          <span>{{ this.detail.shop_name }}</span>
        </el-form-item>
        <el-form-item label="充值金额" prop="amount">
          <el-input type="number" style="width: 330px;" size="small" v-model.trim="goodsAuditForm.amount"
            placeholder="请输入数字，最多保留小数点后两位，例如100.10" @input.native="changeInputValue($event)" />
        </el-form-item>
        <el-form-item label="支付方式" prop="recharge_way" style="margin-bottom:0">
          <el-radio v-model="goodsAuditForm.recharge_way" :label="0">线下汇款</el-radio>
        </el-form-item>
        <el-form-item prop="recharge_way" style="margin-bottom:0">
          <p style="margin: 0;">请将充值金额汇款到如下账户</p>
          <div v-if="shopDetail.company_name">
            <p style="margin: 0;">公司名称：{{ shopDetail.company_name }}</p>
            <p style="margin: 0;">开户银行：{{ shopDetail.bank_name }}</p>
            <p style="margin: 0;">银行账号： {{ shopDetail.bank_account }}</p>
          </div>
          <p v-else style="color:#E3405B;margin:0">企业尚未配置收款账户信息，请联系企业方提供。</p>
        </el-form-item>
        <el-form-item label="图片凭证" prop="recharge_imgs">
          <el-upload accept=".jpg, .jpeg, .png" v-show="!goodsAuditForm.recharge_imgs" ref="uploadFile"
            :action="`${MixinUploadApi}?scene=goods`" list-type="picture-card" :limit="1" :before-upload="beforeUpload"
            :on-success="handleUploadSuccess">
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">图片大小不超过2M</div>
          </el-upload>
          <div class="background-img" v-if="goodsAuditForm.recharge_imgs">
            <img :src="goodsAuditForm.recharge_imgs" alt="" />
            <div class="mantle">
              <i class="el-icon-delete" @click="handleUploadRemove"></i>
              <el-button round size="mini" class="btn-change-img" @click="handleChangeImg">更换图片</el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="recharge_remark">
          <el-input type="textarea" :rows="2" maxlength="250" placeholder="请输入备注信息，不超过250字"
            v-model="goodsAuditForm.recharge_remark"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="result" size="small">取消</el-button>
          <el-button type="primary" @click="handleConfirm" size="small">提交申请</el-button>
        </div>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_account from "@/api/account";
import * as API_logistics from "@/api/expressCompany";
import { handleDownload } from "@/utils";
import { Foundation } from "@/../ui-utils";
import { RegExp } from '@/../ui-utils'
export default {
  name: "goodsList",
  data () {
    return {
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      detail: "",
      loadingtitle: "充值",
      editVisible: false,
      goodsAuditForm: {
        shop_id: '',//充值商户id
        shop_name: "",    //充值商户名称
        amount: '',//充值金额
        recharge_way: 0,//充值方式
        recharge_imgs: '',//充值凭证图片
        recharge_remark: ''   //充值备注 
      },
      txRules: {
        amount: [
          { required: true, message: "请输入充值金额", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const money = /^\d+(\.\d{1,2})?$/
                if (value > 10000000) {
                  callback(new Error('输入最大不超1000万'))
                } else if (!RegExp.money.test(value) || !money.test(value)) {
                  callback(new Error("请输入正确的金额"));
                } else {
                  callback()
                }
              } else {
                callback()
              }
            }, trigger: 'blur'
          }

        ],
        recharge_way: [
          { required: true, trigger: 'change' }],
        recharge_imgs: [
          { required: true, message: "请上传图片凭证", trigger: "change" }
        ]
      },
      shopDetail: {}
    };
  },
  mounted () {
    this.getAcountMoney();
    this.getBackAdress();
  },
  methods: {
    getBackAdress () {
      API_account.getBackAdress().then(res => {
        this.shopDetail = res
      })
    },
    result () {//关闭弹窗
      this.editVisible = false;
      this.goodsAuditForm = {
        shop_id: this.detail.shop_id,//充值商户id
        shop_name: this.detail.shop_name,    //充值商户名称
        amount: '',//充值金额
        recharge_way: 0,//充值方式
        recharge_imgs: '',//充值凭证图片
        recharge_remark: ''   //充值备注 
      }
    },
    getAcountMoney () {//查询充值余额
      API_account.getShopBalance().then(response => {
        console.log(response, "response");
        this.detail = response;
        this.goodsAuditForm = {
          shop_id: this.detail.shop_id,//充值商户id
          shop_name: this.detail.shop_name,    //充值商户名称
          amount: '',//充值金额
          recharge_way: 0,//充值方式
          recharge_imgs: '',//充值凭证图片
          recharge_remark: ''   //充值备注 
        }
      });
    },
    changeInputValue (e) {//格式化计步器
      if (e.target.value.indexOf('.') >= 0) {
        e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 3)
      }
      if (e.target.value.indexOf('-') > -1) {
        e.target.value = e.target.value.replace('-', '')
      }

    },
    // 上传图片
    // 上传之前校验
    beforeUpload (file) {
      let result = true;
      const fileType = file.name.split(".")[1];
      const isLt5M = file.size / 1024 / 1024 < 2;
      if (fileType !== "jpg" && fileType !== "jpeg" && fileType !== "png") {
        result = false;
      }
      if (!result) {
        this.$message.error("请按照提示信息上传图片");
        return false;
      }
      if (!isLt5M) {
        this.$message.error('单张图片不可超过2M');
        return false;
      }
    },
    // 上传成功回调
    handleUploadSuccess (response, file, fileList) {
      this.goodsAuditForm.recharge_imgs = response.url;
    },
    // 删除文件
    handleUploadRemove () {
      this.goodsAuditForm.recharge_imgs = "";
      this.$refs.uploadFile.clearFiles();
    },
    // 更换图片
    handleChangeImg () {
      this.$refs.uploadFile.clearFiles();
      this.$refs.uploadFile.$children[1].$refs.input.click();
    },
    handleConfirm () {//充值
      this.$refs.goodsAuditForm.validate(valid => {
        let datas = {
          ...this.goodsAuditForm
        }
        if (valid && this.shopDetail.company_name && this.shopDetail.company_name !== '') {
          API_account.rechargeApply(datas).then((response) => {
            this.$message.success('充值申请提交成功');
            this.editVisible = false;
            this.getAcountMoney();
          })
        }
      });
    },
    /** 打开充值弹窗 */
    recharge () {
      this.editVisible = true
    }
  }
};
</script>


<style lang="scss" scoped>
.rechargeFrom {}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.tipBox {
  background: #fff;
  padding: 20px;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
}

.tipBox p {
  margin: 0;
  line-height: 18px;
  margin-bottom: 15px;
  font-size: 14px;
}

.tipBox .ye {
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  padding: 15px 0px;
}

.tipBox .right {
  float: right;
  font-size: 12px;
  color: #333;
}

/deep/ .el-form-item__content {
  .background-img {
    width: 167px;
    height: 167px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .mantle {
      width: 167px;
      height: 167px;
      display: none;
      position: absolute;
      top: 0;
      background-color: black;
      opacity: 0.5;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 23px;
        position: relative;
        left: 85%;
      }

      .btn-change-img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: 15%;
        background-color: black;
        opacity: 0.6;
        color: white;
      }
    }
  }

  .background-img:hover {
    .mantle {
      display: block;
    }
  }
}
</style>
